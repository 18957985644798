import {Typography,} from 'antd';
import React, {Suspense, useEffect, useRef, useState} from "react";
import BayesCard from "./charts";
import {useFetch} from "use-http";
import {CalculatorForm} from "./antform";
import {geekblue} from "@ant-design/colors";


let HeaderText = () => {
    return (
        <div>
            <Typography.Title level={1}>
               <span style={{color: geekblue[5]}}>A-B.Tools
               </span>
                <br/>
                <span>
                Awesome Bayesian A/B Test Calculator
                </span>
            </Typography.Title>
            <Typography.Paragraph>
                <Typography.Title level={5}>
                    answering questions such as ...
                </Typography.Title>
                <Typography.Paragraph>
                    "What's the probability of my new version being at least X percent better?"
                </Typography.Paragraph>
                <Typography.Paragraph>
                    "Can I stop my experiment?"
                </Typography.Paragraph>
                <Typography.Paragraph>
                    "How many users do I need?"
                </Typography.Paragraph>
            </Typography.Paragraph>
        </div>)
}


export function transformData(data) {
    let plotData = {}
    plotData.x = data.plot_ecdf.x.map(function (e, i) {
        return e * 100 // Convert to percent
    })
    plotData.y = data.plot_ecdf.y.map(function (e, i) {
        return (1 - e) * 100 // Convert to percent and invert axis
    })

    let interpretation = {}
    interpretation.prob_test_eob = data.stats.prob_test_eob
    interpretation.hdi_lo = data.stats.hdi_lo
    interpretation.hdi_hi = data.stats.hdi_hi

    return {
        plot: plotData,
        interpretation: interpretation,
        recommendation: data.recommendation,
        stats: data.stats,
        sample_size: data.sample_size,
    }
}


export default function CalculatorView() {
    const plotRef = useRef(null)
    const {post, response, loading} = useFetch()
    const [data, setData] = useState({})


    const scrollToPlot = () => {
        plotRef.current?.scrollIntoView({behavior: "smooth"})
    }

    useEffect(() => {
        scrollToPlot()
    }, [data]);

    async function postCalc(values) {
        const results = await post('/calc/', {...values})
        window.plausible('postCalc')
        if (response.ok) {
            let transformed = transformData(results.data)
            setData(transformed)
        }
    }


    return (
        <div>
            <HeaderText/>
            <CalculatorForm loading={loading} postCalc={postCalc}/>
            <Suspense fallback={<div/>}>
                    <BayesCard data={data} plotRef={plotRef} showHeader={true}/>
            </Suspense>
            <div> How does this work?</div>
            <Typography.Link href={"https://docs.a-b.tools"} target={"_blank"}>
                Vist our FAQ
            </Typography.Link>
            <div> to learn more</div>
        </div>
    )
}
