import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {Provider} from "use-http";
import {BrowserRouter} from "react-router-dom";
import {IndexRouter} from "./components/common/routers";

const httpOptions = {
    cachePolicy: "no-cache",

}

Sentry.init({
    enabled: process.env.REACT_APP_SENTRY_ENV === "production",
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE/100,
});


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider url={process.env.REACT_APP_API_URL} options={httpOptions}>
                <IndexRouter/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
