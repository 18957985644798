import {Alert, Button, Card, Col, Divider, List, Row, Slider, Space, Typography} from "antd";
import imgProductMobile from '../../img/product.webp';
import {geekblue, grey} from '@ant-design/colors';
import {Link} from "react-router-dom";
import {ApiOutlined, CheckCircleTwoTone, DashboardOutlined, ExperimentOutlined} from "@ant-design/icons";
import {useState} from "react";

export let TrialBanner = () => {
    return(
        <Alert
            message="Free Trial: User accounts are free and always will be. Each workspace comes with a free 30-day trial. No payment details required."
            type="warning"
            closable
        />
    )
}

let PricingBox = props => {
    let [inputVal, setInputVal] = useState(10)

    let handleChange = value => {
        setInputVal(value)
    }

    let pricingDict = {
        10: {
            volume: "< 10k",
            price: "€6 / month"
        },
        20: {
            volume: "< 100k",
            price: "€12 / month"
        },
        30: {
            volume: "< 200k",
            price: "€20 / month"
        },
        40: {
            volume: "< 500k",
            price: "€30 / month"
        },
        50: {
            volume: "< 1m",
            price: "€50 / month"
        },
        60: {
            volume: "< 2m",
            price: "€70 / month"
        },
        70: {
            volume: "< 5m",
            price: "€100 / month"
        },
        80: {
            volume: "< 10m",
            price: "€150 / month"
        },
        90: {
            volume: "10m+",
            price: "Contact Us"
        },
    }


    let DisplayVolume = () => {
        return (
            <Card size={"small"} bordered={false}>
                <Space direction={"vertical"}>
                    <Typography.Text type={"secondary"} strong>
                        Monthly Events
                    </Typography.Text>
                    <Typography.Text strong style={{fontSize: "x-large"}}>
                        {pricingDict[inputVal].volume}
                    </Typography.Text>
                </Space>
            </Card>
        )
    }

    let DisplayPrice = () => {
        return (

            <Card size={"small"} bordered={false}>
                <Space direction={"vertical"}>
                    <Typography.Text type={"secondary"} strong>
                        Price
                    </Typography.Text>

                    <Typography.Text strong style={{fontSize: "x-large"}}>
                        {pricingDict[inputVal].price}
                    </Typography.Text>
                </Space>
            </Card>
        )
    }

    let DisplayFeatures = () => {
        const data = [
            {
                title: 'Unlimited Experiments',
            },
            {
                title: 'Unlimited Workspace Users',
            },
            {
                title: 'Scheduled Reports',
            },
        ];
        return (
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 3,
                }}
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                        <Card bordered={false}>
                            <Space>
                                <CheckCircleTwoTone twoToneColor="#52c41a"/> {item.title}
                            </Space></Card>
                    </List.Item>
                )}
            />
        )
    }
    return (
        <div>
            <HighLightbox title={"Pricing"}>
                <Card bordered={false}>
                    <Row>
                        <Col xs={24}>
                            <TrialBanner/>
                        </Col>
                        <Col xs={12}>
                            <DisplayVolume/>
                        </Col>
                        <Col xs={12}>
                            <DisplayPrice/>
                        </Col>
                    </Row>
                    <Slider
                        // marks={marks}
                        step={10}
                        tipFormatter={null}
                        onChange={handleChange}
                        defaultValue={inputVal}
                        min={10}
                        max={90}/>
                </Card>
                <Divider> Included Features</Divider>
                <DisplayFeatures/>

            </HighLightbox>
        </div>
    )
}


let HighLightbox = props => {
    return (
        <div style={{
            marginTop: "24px",
            width: "100%",
            margin: "0 auto",
            borderRadius: "10px",
            padding: "7px",
            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
        }}
        >
            <Typography.Title level={3} style={{marginTop: "10px"}}>
                {props.title}
            </Typography.Title>
            {props.children}
        </div>

    )
}
let ProductImage = () => {
    return (
        <HighLightbox>
            <img src={imgProductMobile} alt={"Product Preview"}
                 style={{
                     maxWidth: "100%",
                     height: "auto",
                     marginTop: "24px",
                     marginBottom: "24px",
                 }}
            />
        </HighLightbox>
    )
}

const ActionButtons = () => {
    return (
        <div>
            <Row gutter={[16, 8]} justify="center">
                <Col xs={24} sm={8} md={8} lg={7} xl={7}>
                    <Link to={"/calculator"}>
                        <Button type={"secondary"} size={"large"} shape={"round"}
                                style={{width: "173px", height: "58px"}}>
                            Try Calculator
                        </Button>
                    </Link>
                </Col>
                <Col xs={24} sm={8} md={8} lg={7} xl={7}>
                    <Link to={"/register"}>
                        <Button type={"primary"} size={"large"} shape={"round"}
                                style={{width: "173px", height: "58px"}}>
                            Start Free Trial
                        </Button>
                    </Link>
                </Col>
            </Row>
        </div>
    )
}


const HeaderText = () => {
    return (
        <div>

            <Typography.Title level={1}>
               <span style={{color: geekblue[5]}}>A-B.Tools
               </span>
                <br/>
                <span>Simple and privacy-friendly A/B testing tool</span>
            </Typography.Title>

            <Typography.Title level={4} style={{color: grey[1]}}>
                Lightweight and open-source analytics powered by Bayesian statistics
                <br/>
                Get easily interpretable results for your splits - no cookies or JavaScript required
            </Typography.Title>
        </div>
    )
}

let BenefitCard = props => {
    return (
        <Card bordered={false}>
            {props.icon}
            <Card bordered={false}>
                <Space direction={"vertical"}>
                <Typography.Text style={{textAlign: "center", fontSize:"large"}} strong>
                    {props.title}
                </Typography.Text>
                <Typography.Paragraph>
                    {props.text}
                </Typography.Paragraph>
                </Space>
            </Card>
        </Card>
    )
}

const ProductBenefits = () => {
    let iconStyle =
        {
            fontSize: '250%',
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            color: geekblue[4],
        }
    return (
        <HighLightbox title={"Benefits"}>
            <Row>
                <Col
                    xs={{span: 24, offset: 0}}
                    sm={{span: 12, offset: 0}}
                    lg={{span: 8, offset: 0}}

                >
                    <BenefitCard icon={<DashboardOutlined style={{...iconStyle}}/>}
                                 title={<span>Get Results <i>Fast</i> </span>}
                                 text={"By allowing you to incorporate your domain-specific knowledge our statistical model allows you to get results more quickly"}

                    />
                </Col>
                <Col
                    xs={{span: 24, offset: 0}}
                    sm={{span: 12, offset: 0}}
                    lg={{span: 8, offset: 0}}
                >
                    <BenefitCard icon={<ExperimentOutlined style={{...iconStyle}}/>}
                                 title={"No Stats Required"}
                                 text={"A-B.Tools does all the data-crunching for you and delivers clear and actionable recommendations - no data-science skills required"}/>
                </Col>
                <Col
                    xs={{span: 24, offset: 0}}
                    sm={{span: 12, offset: 6}}
                    lg={{span: 8, offset: 0}}
                >
                    <BenefitCard icon={<ApiOutlined style={{...iconStyle}}/>}
                                 title={"Easy Integration"}
                                 text={"Data collection via simple REST calls allows you to get started quickly and without taking on any extra dependencies"}

                    />

                </Col>
            </Row>
        </HighLightbox>
    )
}

export const LandingPage = () => {
    return (
        <div>
            <Space direction={"vertical"} size={"large"}>
                <HeaderText/>
                <ActionButtons/>
                <ProductImage/>
                <ProductBenefits/>
                <PricingBox/>
            </Space>
        </div>

    )

}
