import {Button, Dropdown, Menu, Popconfirm} from "antd";
import {Link, useHistory} from "react-router-dom";
import {authHelper} from "./auth";
import Icon, {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {ReactComponent as LogoSvg} from "./icon.svg";
import React from "react";

export const LinkButton = (props) => {
    return (
        <Link to={props.to}>
            <Button type={"link"} block>
                {props.text}
            </Button>
        </Link>
    )
}


export const LogoButton = (props) => {
    let history = useHistory()
    let handleClick = () => {
        props.private ? history.push("/app/workspaces") : history.push("/")
    }
    return (
        <Button style={{
            float: "left",
            height: "31px",
            fontWeight: 500,
            fontSize: "larger",
            color: "white",
            marginTop:"16px"
        }}
                onClick={handleClick}
                type={"link"}
                disabled={props.disabled}
                icon={<Icon component={LogoSvg}/>}
        >
            A-B Tools
        </Button>
    )
}

export const DangerButton = (props) => {
    return (
        <Popconfirm
            title={props.confirmLabel}
            onConfirm={props.onDelete}
            onCancel={() => {
            }}
            okText="Delete"
            cancelText="Cancel"
        >
            <Button danger> {props.buttonText} </Button>
        </Popconfirm>
    )
}

export const LogOutButton = () => {
    const history = useHistory()
    const handleLogOut = () => {
        history.push("/")
        authHelper.destroyToken()
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<LogoutOutlined/>} onClick={handleLogOut}>
                Log-Out
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu}>
            <Button style={{
                float: "left",
                height: "31px",
                color: "white",
                marginTop:"16px",
            }}
                    type={"link"}
                    icon={<UserOutlined/>}
            >
            </Button>
        </Dropdown>

    )
}
