import React from 'react';
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory';
import {List, Space, Tag, Typography} from 'antd';
import {RightOutlined} from "@ant-design/icons";
import {ContentCard} from "../common/layout";

const Plot = createPlotlyComponent(Plotly);
let chipTest = <Tag color={"geekblue"}>Test</Tag>
let chipControl = <Tag>Control</Tag>

const BayesCard = (props) => {
    if (props.data.plot === undefined) {
        return <div/>

    }
    if(props.showHeader) {
        return (<ContentCard title={"Bayesian Split Analyzer"}>
            <div>
                <div ref={props.plotRef}/>
                <CdfPlot data={props.data}/>
                <RecommendationText data={props.data}/>
                <InterpretationText data={props.data}/>
            </div>
        </ContentCard>)
    }
    else
    return (
        <div>
            <div ref={props.plotRef}/>
            <CdfPlot data={props.data}/>
            <RecommendationText data={props.data}/>
            <InterpretationText data={props.data}/>
        </div>
    )
}

const ResultList = (props) => {
    return(
       <div>
           <Typography.Title level={5}>
               {props.type}
           </Typography.Title>
           <List
               itemLayout="horizontal"
               dataSource={props.data}
               renderItem={item => (
                   <List.Item>
                       <div>
                           <Space>
                               <RightOutlined/> {item}
                           </Space>
                       </div>
                   </List.Item>
               )}
           />
       </div>
    )
}


export const RecommendationText = props => {
    let stats = props.data.stats
    let recommendationData = props.data.recommendation
    let requiredUsers = Math.round(props.data.sample_size.remaining/2)
    let requiredUsersText =   `at current conversion rates, you'll need approximately ${requiredUsers} more users per group`
    let recommendation

    if (recommendationData.stop) {
        if (recommendationData.winner) {
            let winnerChip = stats.b_mean >= stats.a_mean ? chipTest : chipControl
            recommendation = (
                <div>
                    Stop experiment and <span> implement version </span> {winnerChip}
                </div>
            )
        } else {
            recommendation = (
               <div>
                   No meaningful difference found. Stop test and implement either version
               </div>
            )
        }
    } else {
        if(props.data.sample_size.converged){
            recommendation = (
                <span>
                {`No winner yet - keep testing (${requiredUsersText})`}
            </span>
            )

        }
        else{
            recommendation = (
                <span>
                {`No winner yet - keep testing (could not determine remaining required sample size)`}
            </span>
            )

        }
    }
    return  <ResultList type={"Recommendation"}  data={[recommendation]}/>
}

const formatNumber = (num) =>{
    if (num < 0){
        return <Typography.Text type={"danger"}>{num}%</Typography.Text>
    }
    else{
        return <Typography.Text type={"success"}>+{num}%</Typography.Text>
    }

}

const roundNum = (num) =>{
    return Math.round((num + Number.EPSILON) * 1000)/10
}

export const RequiredUsersText = (props) => {

        return (
            <span>

            </span>
        )
    }
export const InterpretationText = (props) => {
    if (props.data.plot === undefined) {
        return <div>No Data</div>
    }

    let p5 = roundNum(props.data.interpretation.hdi_lo)
    let p95 = roundNum(props.data.interpretation.hdi_hi)


    let messageP95 = () => {
        return (
            <span>
                {chipTest} most likely yields and uplift between {formatNumber(p5)} and {formatNumber(p95)}
            </span>
        )
    }

    let messageProbEob = () => {
        let p_test_eob = props.data.interpretation.prob_test_eob
        p_test_eob = p_test_eob.toFixed(2) * 100
        return (

            <span>
                <span>
                    {`There's a ${p_test_eob}% chance `}
                </span>
                {chipTest}
                <span>
                    {" is at least as good as "}
                </span>
                {chipControl}
            </span>
        )
    }

    let resultsData = [
        messageProbEob(props.data),
        messageP95()
    ]
    return  <ResultList type={"Interpretation"}  data={resultsData}/>
}

export const CdfPlot = (props) => {
    let y = props.data.plot.y
    let x = props.data.plot.x
    return (
        <Plot
            style={{width: "100%",
            height:"100%"}}
            useResizeHandler={true}
            data={[
                {
                    x: x,
                    y: y,
                    hovertemplate: 'Uplift: %{x:.1f}% <br>Prob: %{y:.1f}%<extra></extra>',
                    line: {shape: 'spline'},
                    type: 'lines',
                    mode: 'lines',
                    marker: {color: 'orange'},
                },
            ]}
            config={{
                displaylogo: false,
            }}
            layout={{
                title: 'Expected Uplift Chart',
                autosize: true,
                margin: {
                    l: 40,
                    r: 40,
                    b: 100,
                    t: 100,
                },
                modebar: {
                    remove: ["autoscale", "zoomOut2d", "zoomIn2d", "zoomin", "zoomout", "zoom", "pan"]
                },
                yaxis: {
                    title: "Cumulative Probability (%)",
                    rangemode: "nonnegative",
                    range: [0, 101]
                },
                xaxis: {
                    title: "Minimum Relative Uplift (Test vs Control; %)",
                },
            }}
        />
    );
}

export default BayesCard
