import {PageHeader} from "./workspaceListView";
import {useFetch} from "use-http";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Col, Empty, Form, Result, Row, Select, Skeleton, Space, Table, Typography} from "antd";
import BayesCard from "./charts";
import {transformData} from "./calculatorView";
import {v4 as uuidv4} from 'uuid';
import {ContentCard} from "../common/layout";
import {DisplayPayload} from "./experimentEditView";
import {CalculatorFormExpertSettings} from "./antform";

const pd = require("danfojs")
let zipObject = require("lodash/zipObject")

let groupData = (data, selectedCols, groupByCols) => {
    let df = new pd.DataFrame(data)
    let filtered = selectedCols.filter(function (value, index, arr) {
        return value !== "successRate"
    });
    df = df.loc({columns: filtered})
    let dfGrouped = df.groupby(groupByCols).sum()

    let trials = dfGrouped["trials_sum"].values
    let hits = dfGrouped["hits_sum"].values
    let successRate = trials.map(function (num, idx) {
        return {
            successRate: hits[idx] / num,
            id: uuidv4()
        }
    });
    let dfS = new pd.DataFrame(successRate)

    let dfJoined = pd.concat({df_list: [dfGrouped, dfS], axis: 1})

    dfJoined.rename({mapper: {"hits_sum": "hits", "trials_sum": "trials"}, inplace: true})

    return dfJoined
}

let DimensionSelector = (props) => {
    const {Option} = Select;
    const children = [
        (<Option key={"date"}>Date</Option>),
        (<Option key={"hour"}>Hour</Option>),
    ];

    function handleChange(key) {
        props.setSelectedDimensions(key)
    }

    return (
        <Select mode="tags" style={{width: '100%'}} defaultValue={props.selectedDimensions}
                placeholder="Select Dimensions"
                onChange={handleChange}>
            {children}
        </Select>
    )
}

let NoDataPanel = _ => {
    return (
        <Result
            extra={
                <div>
                    <Typography.Title level={1}>No Data</Typography.Title>
                    <span>
                            <Typography.Text
                                level={5}>Start collecting data by pointing your clients towards our API</Typography.Text>
                                <br/>
                                <Typography.Link
                                    target={"_blank"}
                                    href={"https://docs.a-b.tools/get-started/Experiment-Setup#tracking-endpoints"}>Show Documentation</Typography.Link>
                            </span>
                    <div style={{"textAlign": "left"}}>
                        {/*<DisplayPayload wsId={wsId} controlSlug={slugData.control} testSlug={slugData.test}/>*/}
                    </div>
                </div>
            }/>
    )
}
let ResultsPanel = (props) => {
    const {loading, response, get} = useFetch()
    const [resultsData, setResultsData] = useState(undefined)
    const [groupedData, setGroupedData] = useState([])
    const [selectedColumns, setSelectedColumns] = useState([])
    const [selectedDimensions, setSelectedDimensions] = useState([])

    async function loadResultsData() {
        const res = await get(`/experiments/${props.exId}/results`)
        if (response.ok) {
            setResultsData(res.data)
        }
    }

    useEffect(() => {
        let dataCols = [
            {
                title: "Variation-Type",
                dataIndex: 'variation_type',
                key: 'variation_type',
            },
            {
                title: "Date",
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: "Hour",
                dataIndex: 'hour',
                key: 'hour',
            },
            {
                title: "Trials",
                dataIndex: 'trials',
                key: 'trials',
            },
            {
                title: "Hits",
                dataIndex: 'hits',
                key: 'hits',
            },
            {
                title: "Success Rate",
                dataIndex: 'successRate',
                key: 'successRate',
                render: x => <div>{parseFloat(x * 100).toFixed(2) + "%"}</div>
            },
        ]
        let filterData = () => {
            let groupByCols = ["variation_type"].concat(selectedDimensions)
            let selectedCols = groupByCols.concat(["hits", "trials", "successRate"])

            let newCols = dataCols.filter(function (itm) {
                return selectedCols.includes(itm.key)
            });

            setSelectedColumns(newCols)

            let groupedData = groupData(resultsData, selectedCols, groupByCols)

            let keys = groupedData.$columns
            let values = groupedData.$data

            let mapped = values.map((item, index) => {
                return zipObject(keys, item)
            })
            setGroupedData(mapped)
        }
        if (resultsData === undefined && !loading) {
            loadResultsData()
        }
        if (resultsData !== undefined && resultsData && resultsData.length > 0) filterData()
    }, [resultsData, selectedDimensions])

    if (loading) return <Skeleton active/>
    else if (resultsData && resultsData.length === 0) return <NoDataPanel/>

    return (
        <div>
            <Space direction="vertical" style={{width: "100%"}} size={"large"}>
                <DimensionSelector selectedDimensions={selectedDimensions}
                                   setSelectedDimensions={setSelectedDimensions}/>
                <Table dataSource={groupedData.length > 0 ? groupedData : []} columns={selectedColumns}
                       pagination={false}
                       rowKey={record => record.id}
                />
            </Space>
        </div>
    )
}

export const ExperimentsResultsView = () => {
    const {exId} = useParams()


    return (
        <div>
            <PageHeader title={"Experiment Results"}/>
            <ContentCard title={"Results Table"}>
                <ResultsPanel exId={exId}/>
            </ContentCard>

            <ContentCard title={"Split-Analyzer"}>
                <SplitAnalyzer exId={exId}/>
            </ContentCard>

            <ContentCard title={"Tracking-Endpoints"}>
                <DisplayPayload exId={exId}/>
            </ContentCard>

        </div>
    )
}

let SplitAnalyzer = props => {
    const [initialLoadComplete, setInitialLoadComplete] = useState(false)
    const [moreDataRequired, setMoreDataRequired] = useState(true)
    const [data, setData] = useState([])
    const {loading, response, post} = useFetch()
    const [form] = Form.useForm();

    async function postCalc(values) {
        const results = await post('/calc/', {...values})
        if (response.ok) {
            let transformed = transformData(results.data)
            setData(transformed)
        }
    }

    const onFinish = () => {
        let validate = form.validateFields()
        validate.then((values => {
                values["experiment"] = props.exId
                // console.log(values)
                postCalc(values)
            })
        )
    }
    useEffect(() => {
        getBayesData()
    }, []) // componentDidMount

    async function getBayesData() {
        let res = await post("calc/", {experiment: props.exId})
        setInitialLoadComplete(true)
        if (response.ok) {
            setMoreDataRequired(false)
            let transformed = transformData(res.data)
            setData(transformed)
        }
    }

    if (!initialLoadComplete && loading) return <Skeleton active/>
    else if (moreDataRequired) return (<Empty description={
        <span>
            Not enough data to perform detailed analysis. Please collect some more and try again.
      </span>
    }/>)
    return (
        <div>
            <Row gutter={[16, 8]}>
                <Col className="gutter-row" span={24}>
                    <BayesCard data={data}/>
                </Col>
                <Col className="gutter-row" span={24}>
                    <div style={{textAlign: "center"}}>
                        <CalculatorFormExpertSettings form={form}>
                            <Button type="primary" onClick={onFinish} style={{width: "100%"}} size={"large"}
                                    loading={loading}>Re-Run Calculation</Button>
                        </CalculatorFormExpertSettings>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
