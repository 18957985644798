import {Link, useHistory, useParams} from 'react-router-dom'
import {Button, Form, Result} from "antd";
import React, {useState} from "react";
import {useFetch} from "use-http";
import {notify} from "../common/notify";
import {authHelper} from "../common/auth";
import {BaseForm, FormFieldEmail, FormFieldPassword} from "../common/forms";
import {TrialBanner} from "./landingPage";

export const ResetPassword = (props) => {
    let {uid, token} = useParams()
    let history = useHistory()
    const {post, loading, response, error} = useFetch()
    const [form] = Form.useForm();

    async function onFinish(values) {
        const res = await post('/auth/users/reset_password_confirm/', {
            uid: uid,
            token: token,
            new_password: values.password,
        })
        if (response.ok) {
            notify("success", "Success", "Password has been changed successfully. Please login to continue.")
            history.push("/login")
        } else {
            if ('token' in res) {
                notify("error", "Activation Error", res.token.join(" "))
            }
            if ('uid' in res) {
                notify("error", "Activation Error", res.uid.join(" "))
            }
            if ('new_password' in res) {
                notify("error", "Activation Error", res.new_password.join(" "))
            }
        }

    }

    return (
        <BaseForm form={form}
                  title={"Reset Password"}
                  onFinish={onFinish}
                  loading={loading}
                  submitButtonText={"Submit"}
        >
            <FormFieldPassword label={"New Password"}/>
        </BaseForm>
    )

}


export const ActivateUser = () => {
    let {uid, token} = useParams()
    let [activationSuccess, setActivationSuccess] = useState(false)
    const {post, loading, response, error} = useFetch()

    async function handleConfirm(values) {
        const res = await post('/auth/users/activation/', {
            uid: uid,
            token: token,
        })
        if (response.ok) {
            setActivationSuccess(true)
        } else {
            if ('token' in res) {
                notify("error", "Activation Error", res.token.join(" "))
            }
            if ('uid' in res) {
                notify("error", "Activation Error", res.uid.join(" "))
            }
        }
    }

    if (activationSuccess) {
        return (
            <Result
                status="success"
                title="E-Mail Confirmed"
                subTitle={<div>Your e-mail has been confirmed successfully. <br/> You may now login using the
                    credentials provided during registration.</div>}
                extra={[
                    <Link to={"/login"}>
                        <Button type={"primary"}>
                            Go to Login
                        </Button>
                    </Link>
                ]}
            />
        )
    }


    return (
        <Result
            status="info"
            title="Confirm E-Mail"
            subTitle="Please confirm your e-mail address by selecting the 'Confirm' button below"
            extra={[
                <Button type="primary" key="console" onClick={handleConfirm} loading={loading}>
                    Confirm
                </Button>,
            ]}
        />
    )
}
export const RegisterForm = () => {
    const [form] = Form.useForm();
    const {post, loading, response, error} = useFetch()

    async function onFinish(values) {
        window.plausible('registerUser')
        const res = await post('/auth/users/', {
            email: values.email,
            password: values.password,
        })
        if (response.ok) {
            notify("success", "Registration Successful", "We have sent you an e-mail to confirm your address.")
        } else {
            if ('email' in res) {
                notify("warning", "Error", res.email.join(" "))
            }
            if ('password' in res) {
                notify("warning", "Error", res.password.join(" "))
            }
        }
    }


    return (
        <div>
            <TrialBanner/>
            <BaseForm form={form}
                      title={"Create New Account"}
                      onFinish={onFinish}
                      loading={loading}
                      submitButtonText={"Register"}
            >
                <FormFieldEmail/>
                <FormFieldPassword/>
                <Form.Item>
                    Already have an account? <Link to={"/login"}>Sign In</Link>
                </Form.Item>
            </BaseForm>
        </div>
    )
}
export const ForgotPassword = () => {
    const [form] = Form.useForm();
    const {post, loading, response, error} = useFetch()

    async function onFinish(values) {
        const res = await post('/auth/users/reset_password/', {email: values.email})
        if (response.ok) {
            notify("success", "", "Password reset instructions have been sent to you via e-mail. Please check your inbox")
        } else {
            console.log(res)
        }
    }

    return (
        <BaseForm form={form}
                  title={"Reset Password"}
                  onFinish={onFinish}
                  loading={loading}
                  submitButtonText={"Submit"}
        >
            <FormFieldEmail/>
        </BaseForm>
    )
}
export const LoginForm = () => {
    let history = useHistory();
    const [form] = Form.useForm();
    const {post, loading, response, error} = useFetch()

    async function onFinish(values) {
        const res = await post('auth/jwt/create/', {email: values.email, password: values.password})
        if (response.ok) {
            authHelper.storeToken(res)
            history.push("/app/workspaces")
        } else {
            if (response.status === 401) {
                notify("error", "Login Failed", "Please verify e-mail/password and try again")
            } else {
                notify("error", "Authentication Error", "Login failed")
            }
        }
    }

    return (
        <BaseForm form={form}
                  title={"Login"}
                  onFinish={onFinish}
                  loading={loading}
                  submitButtonText={"Login"}
        >
            <FormFieldEmail/>
            <FormFieldPassword/>
            <Form.Item>
                Don't have an account? <Link to={"/register"}>Create Account</Link>
                <br/>
                <br/>
                Forgot your password? <Link to={"/forgot-password"}>Reset Password</Link> <br/>
            </Form.Item>
        </BaseForm>
    )
}


