import React, {Suspense} from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {useHistory, useParams} from "react-router-dom";
import {BaseForm, FormFieldDescription, FormFieldName, FormFieldSlug,} from "../common/forms";
import {PageHeader} from "./workspaceListView";
import {useFetch} from "use-http";
import {Col, Form, Row, Skeleton, Tabs} from "antd";
import {useEffect, useState} from "react";
import {notify} from "../common/notify";
import {ContentCard, DangerZone} from "../common/layout";
import {DangerButton} from "../common/input";

export let DisplayPayload = props => {
    const {loading, response, get, post} = useFetch(`/variations?experiment=${props.exId}`)
    const [slugData, setSlugData] = useState({})

    const {TabPane} = Tabs

    async function loadSlugs() {
        const res = await get()
        if (response.ok) {
            const test = res.filter(item => item.type === "Test")[0]
            const control = res.filter(item => item.type === "Control")[0]
            let slugs = {
                control: control.slug,
                test: test.slug,
                wsId: test.workspace_slug
            }
            setSlugData(slugs)
        }
    }

    // componentDidMount
    useEffect(() => {
        if(props.exId){
            loadSlugs()
        }
    }, [props.updateTimeStamp])

    if(loading) return <Skeleton active />

    return (
            <Tabs defaultActiveKey="1">
                <TabPane tab="JavaScript" key="javascript">
                    <RenderPayloadString wsId={slugData.wsId} testSlug={slugData.test}
                                         controlSlug={slugData.control}
                                         language={"javascript"}/>
                </TabPane>
                <TabPane tab="Python" key="python">
                    <RenderPayloadString wsId={slugData.wsId}
                                         testSlug={slugData.test}
                                         controlSlug={slugData.control}
                                         language={"python"}/>
                </TabPane>
            </Tabs>
    )
}
let RenderPayloadString = props => {
    let language = "javascript"

    const comment1 = "use variation depending on user group"
    const comment2 = "use calltype 'trial' upon assigning group-membership, 'hit' on success"

    let outText = ""
    let payload = `payload = {"workspace":"${props.wsId}", "variation": "<${props.controlSlug}|${props.testSlug}>", "calltype": "<trial|hit>"}`

    if (props.language === "python") {
        language = "python"
        outText = `${payload}
# ${comment1}
# ${comment2}

import requests
requests.post('${process.env.REACT_APP_API_URL}/', json=payload)`
    } else {
        outText = `${payload}
// ${comment1}
// ${comment2}

fetch('${process.env.REACT_APP_STAT_URL}', {method:"POST", body: JSON.stringify(payload)})`
    }

    return (
        <SyntaxHighlighter language={language} style={docco}>
            {outText}
        </SyntaxHighlighter>
    )
}

export const ExperimentEditView = () => {
    const [experimentForm] = Form.useForm();
    const [variationControlForm] = Form.useForm();
    const [variationTestForm] = Form.useForm();
    const history = useHistory()
    const {wsId, exId} = useParams()
    const [experimentData, setExperimentData] = useState({})
    const [variationTestData, setVariationTestData] = useState({})
    const [variationControlData, setVariationControlData] = useState({})
    const [updateTimeStamp, setUpdateTimeStamp] = useState(Date.now())
    const {patch, get, loading, response} = useFetch()

    useEffect(() => {
        loadInitialValues()
    }, []) // componentDidMount

    async function loadVariationData() {
        const res = await get(`/variations?experiment=${exId}`)
        if (response.ok) {
            const test = res.filter(item => item.type === "Test")[0]
            const control = res.filter(item => item.type === "Control")[0]
            setVariationControlData(control)
            setVariationTestData(test)
        }
    }

    async function loadInitialExperimentValues() {
        const res = await get(`/experiments/${exId}/`)
        if (response.ok) setExperimentData(res)
    }

    function loadInitialValues() {
        loadInitialExperimentValues()
        loadVariationData()
    }

    async function genericFinish(form, route, targetData) {
        let touchedFields = form.getFieldsValue(null, meta => meta.touched)
        const res = await patch(route, {...touchedFields})
        if (response.ok) {
            setUpdateTimeStamp(Date.now())
            targetData(res)
        } else {
            notify("error", "Error", JSON.stringify(res))
        }
    }

    async function onExperimentFinish() {
        await genericFinish(experimentForm, `experiments/${experimentData.id}/`, setExperimentData)
    }

    async function onVariationTestFinish() {
        await genericFinish(variationTestForm, `variations/${variationTestData.id}/`, setVariationTestData)
    }

    async function onVariationControlFinish() {
        await genericFinish(variationControlForm, `variations/${variationControlData.id}/`, setVariationControlData)
    }


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    let handleClick = () => {
        history.push(`/app/workspace/${wsId}`)
    }


    return (
        <div>
            <PageHeader title={"Edit Experiment"} buttonText={"Show Workspace"} action={handleClick}/>
            <BaseForm
                title={"General"}
                onFinish={onExperimentFinish}
                onFinishFailed={onFinishFailed}
                form={experimentForm}
                loading={loading}
                initialValues={experimentData}
            >
                <FormFieldName/>
                <FormFieldDescription/>
            </BaseForm>

            <Row gutter={[8, 8]}>
                <Col span={{xs: 24, lg: 12}}>
                    <BaseForm
                        title={"Variation: Control"}
                        onFinish={onVariationControlFinish}
                        onFinishFailed={onFinishFailed}
                        form={variationControlForm}
                        loading={loading}
                        initialValues={variationControlData}
                    >
                        <FormFieldName/>
                        <FormFieldSlug/>
                    </BaseForm>
                </Col>
                <Col span={{xs: 24, lg: 12}}>
                    <BaseForm
                        title={"Variation: Test"}
                        onFinish={onVariationTestFinish}
                        onFinishFailed={onFinishFailed}
                        form={variationTestForm}
                        loading={loading}
                        initialValues={variationTestData}
                    >
                        <FormFieldName/>
                        <FormFieldSlug/>
                    </BaseForm>
                </Col>

            </Row>


            <ContentCard title={"POST Payload"}>
                <DisplayPayload exId={exId} updateTimeStamp={updateTimeStamp}/>
            </ContentCard>


            <ExperimentDangerZone wsId={wsId} experimentId={experimentData.id}/>

        </div>
    )
}

let ExperimentDangerZone = (props) => {
    const {get, response, del} = useFetch()
    const history = useHistory()

    async function onDelete() {
        await del(`/experiments/${props.experimentId}/`)
        if (response.ok) {
            history.push("/app/workspace/" + props.wsId)
        }
    }

    async function onReset() {
        await get(`/experiments/${props.experimentId}/reset`)
        if (response.ok) notify("success", "Data Reset Success", "Collected data has been reset")
    }

    return (
        <DangerZone>
            <DangerButton
                confirmLabel={
                    <div>Are you sure to delete this experiment? <br/>
                        The experiment and all collected results will be lost.
                    </div>
                }
                onDelete={onDelete}
                buttonText={"Delete Experiment"}
            />
            <DangerButton
                confirmLabel={
                    <div>Are you sure to reset this experiment? <br/>
                        All collected results will be lost. <br/>
                    </div>
                }
                onDelete={onReset}
                buttonText={"Reset Collected Data"}
            />
        </DangerZone>
    )
}
