import {useFetch} from "use-http";
import {Button, Col, Row, Table, Typography} from "antd";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {LinkButton} from "../common/input";
import {PrivateLayout} from "../common/layout";
import {tableLoading} from "../common/misc";

export const PageHeader = (props) => {
    return (
        <div>
            <Row wrap={false}>
                <Col flex="none">
                    <div style={{textAlign:"left"}}>
                        <Typography.Title level={2}>
                            {props.title}
                        </Typography.Title>
                    </div>
                </Col>
                <Col flex="auto"/>
                <Col flex="none">
                    <div style={{padding: '0 16px'}}>
                        <Button type={"primary"} onClick={() => props.action()}>
                            {props.buttonText}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export const WorkspaceListView = () => {
    let [data, setData] = useState([])
    const {get, post, loading, response} = useFetch()
    let history = useHistory()

    useEffect(() => {
        loadWorkspaces()
    }, []) // componentDidMount

    async function loadWorkspaces() {
        const res = await get('workspaces')
        if (response.ok) setData(res)
    }

    async function handleClick() {
        const res = await post('/workspaces/')
        if (response.ok) {
            console.log(res)
            history.push(`/app/workspace/${res.slug}/edit`)
        }
    }

    let columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Short Name",
            dataIndex: "slug",
            key: "slug",
            responsive: ['md'],
        },
        {
            title: "",
            dataIndex: "id",
            key: "view-workspace",
            // render: (text, record) => (<Link to={`/app/workspace/${record.slug}`} text={"Open"}>Open</Link>)
            render: (text, record) => (<LinkButton to={`/app/workspace/${record.slug}`} text={"Open"}/>)
        },
    ]
    return (
        <PrivateLayout>
            <PageHeader title={"Workspaces"} buttonText={"New Workspace"} action={handleClick}/>
            <Table  dataSource={data} loading={tableLoading(loading)} columns={columns} rowKey={record => record.id}/>
        </PrivateLayout>
    )
}
