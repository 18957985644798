import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const tableLoading = (loading) => {
    return {
        spinning: loading,
        indicator: <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
    }
}
export const LoadingSpinner = props => {
        const antIcon = <LoadingOutlined style={{
            position: 'absolute', left: '50%', top: '50%',
            fontSize: 75
        }} spin/>;
        return <Spin indicator={antIcon}/>
}
