import '../../App.less';
import React, {lazy, Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
import {PrivateLayout, PublicLayout} from "./layout";
import {ExperimentEditView} from "../pages/experimentEditView";
import {ExperimentListView} from "../pages/experimentListView";
import {ExperimentsResultsView} from "../pages/experimentsResultsView";
import {ActivateUser, ForgotPassword, LoginForm, RegisterForm, ResetPassword} from "../pages/authView";
// import App from "../pages/app";
import {ProtectedRoute} from "./auth";
import {WorkspaceEditView} from "../pages/workspaceEditView";
import {WorkspaceListView} from "../pages/workspaceListView";
import {PrivacyView} from "../pages/privacyView";
import {LandingPage} from "../pages/landingPage";
import {SubscriptionSummary} from "../pages/subscriptionSummary";


export const AppRouter = (props) => {
    return (

        <Switch>
            <Route path={"/app/workspace/:wsId"} component={WorkspaceRouter}/>
            <Route exact path={"/app/workspaces"} component={WorkspaceListView}/>
        </Switch>
    )
}

const WorkspaceRouter = props => {
    let basePath = "/app/workspace/:wsId"
    return (
        <PrivateLayout showSider>
            <Switch>
                <Route exact path={basePath + "/edit"} component={WorkspaceEditView}/>
                <Route exact path={basePath + "/subscription"} component={SubscriptionSummary}/>
                <Route exact path={basePath + "/:exId/edit"} component={ExperimentEditView}/>
                <Route exact path={basePath + "/:exId/results"} component={ExperimentsResultsView}/>
                <Route exact path={basePath} component={ExperimentListView}/>
            </Switch>
        </PrivateLayout>
    )
}

export const PublicRouter = () => {
    const CalculatorView = lazy(() => import("../pages/calculatorView"))

    let RenderCalculatorView = () => {
        return (
            <Suspense fallback={<div>Loading</div>}>
                <CalculatorView/>
            </Suspense>
        )
    }


    return (
        <PublicLayout>
            <Switch>
                <Route path={"/activate/:uid/:token"} component={ActivateUser}/>
                <Route path={"/register"} component={RegisterForm}/>
                <Route path={"/reset_password/:uid/:token"} component={ResetPassword}/>
                <Route path={"/forgot-password"} component={ForgotPassword}/>
                <Route path={"/login"} component={LoginForm}/>
                <Route path={"/privacy"} component={PrivacyView}/>
                <Route path={"/calculator"} component={RenderCalculatorView}/>
                <Route component={LandingPage}/>
            </Switch>
        </PublicLayout>
    )
}


export const IndexRouter = () => {
    const App = lazy(() => import("../pages/app"))
    let RenderApp = () => {
        return (
            <Suspense fallback={<div>Loading</div>}>
                <App/>
            </Suspense>
        )
    }
    return (
        <Switch>
            <ProtectedRoute path={"/app"} component={RenderApp}/>
            <Route component={PublicRouter}/>
        </Switch>
    );
}

