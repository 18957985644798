import {Button, Form, Input, Space} from "antd";
import {useEffect, useState} from "react";
import {ContentCard} from "./layout";


export const FormFieldPassword = (props) => {
    return (
        <Form.Item
            label={props.label && props.label.length > 0 ? props.label : "Password"}
            name="password"
            rules={[
                {
                    required: true,
                    message: 'Password is required',
                },
                // {
                //     validator(_, value) {
                //         let minLength = value.length >= 8
                //         let hasNumber = /\d/.test(value)
                //         let hasText = !(/^\d+$/.test(value))
                //
                //         if (minLength && hasNumber && hasText) {
                //             return Promise.resolve();
                //         } else {
                //             return Promise.reject(new Error('Password must contain numbers and letters and must be at least 8 characters long'));
                //         }
                //     },
                // },
            ]}
        >
            <Input.Password/>
        </Form.Item>
    )
}
export const FormFieldEmail = () => {
    return (
        <Form.Item
            label="E-Mail"
            name="email"
            rules={[
                {
                    type: "email",
                    required: true,
                    message: 'Please enter a valid e-mail address',
                },
            ]}
        >
            <Input/>
        </Form.Item>
    )
}
export const FormFieldName = () => {
    return (<Form.Item
        label="Name"
        name="name"
        rules={[
            {
                required: true,
                message: 'Name is required',
            },
        ]}
    >
        <Input/>
    </Form.Item>)
}
export const FormFieldSlug = () => {
    return (<Form.Item
        label="Slug"
        name="slug"
        rules={[
            {
                required: true,
                message: 'Slug is required',
            },
        ]}
    >
        <Input/>
    </Form.Item>)
}

export const FormFieldDescription = () => {
    const {TextArea} = Input;
    return (
        <Form.Item
            label="Description"
            name="description"
        >
            <TextArea rows={3}/>
        </Form.Item>
    )
}


export const BaseForm = (props) => {
    const [touched, setTouched] = useState(false)
    useEffect(() => {
        props.form.resetFields()
    }, [props.initialValues])

    function onReset() {
        props.form.resetFields()
        setTouched(false)
    }

    let onFinish = () => {
        const values = props.form.getFieldsValue()
        props.onFinish(values)
        setTouched(false)
    }

    return (
        <ContentCard title={props.title}>
            <Form
                form={props.form}
                name="login-form"
                wrapperCol={{
                    span: 12,
                }}
                layout="vertical"
                initialValues={{...props.initialValues}}
                onFinish={onFinish}
                onFinishFailed={props.onFinishFailed}
                onFieldsChange={() => setTouched(true)}
                autoComplete="off"
            >
                {props.children}

                <Form.Item>
                    <Space>
                        <Button loading={touched && props.loading} type="primary" htmlType={"submit"}
                                disabled={!touched}>

                            {props.submitButtonText && props.submitButtonText.length > 0 ? props.submitButtonText : "Submit"}
                        </Button>
                        <Button onClick={onReset} htmlType={"button"}
                                disabled={!touched}>
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>

        </ContentCard>
    )
}
