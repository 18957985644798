import {Typography} from "antd";

export const PrivacyView = (props) => {
    return (
        <div style={{textAlign: "left"}}>
            <Typography.Title>
                A-B.Tools Privacy Policy
            </Typography.Title>
            <Typography.Paragraph>
                <span style={{fontWeight: "bold"}}>
                   TL;DR:
                </span> <span>
                We always aim to collect as little data as possible.
                For visitors of a-b.tools do not use cookies and we do not collect any personal data.
                If you decide to create an account with us, we only collect and process the bare minimum and do not share/market/sell any data with any third party whatsover.
                </span>
            </Typography.Paragraph>

            <Typography.Paragraph>
                At A-B.Tools, we are committed to complying with GDPR, CCPA, PECR and other privacy regulations
                on our website and the services we provide.
                The privacy of your data — and it is your data, not ours! — is a big deal to us.
            </Typography.Paragraph>

            <Typography.Paragraph>
                In this policy, we lay out what data we collect and why, how your data is handled and your rights to
                your data.
                We promise we never sell your data: never have, never will.
            </Typography.Paragraph>

            <Typography.Title>
                Visitors a-b.tools
            </Typography.Title>
            <Typography.Paragraph>
                The privacy of our website visitors is important to us so we do not track any individual people. As a
                visitor to a-b.tools, this means:
                <ul>
                    <li>
                        No personal information is collected
                    </li>
                    <li>
                        No data entered into the calculator is collected
                    </li>
                    <li>
                        No information such as cookies is stored in the browser
                    </li>
                    <li>
                        No information is shared with, sent/sold to third-parties, including advertising companies
                    </li>
                </ul>
                We run a plausible.io analytics script to collect some anonymous usage data for statistical
                purposes. <br/>
                The goal is to track overall trends in our website traffic, it is not to track individual
                visitors. <br/>
                All the data is in aggregate only and no personal data is collected. <br/>
                Data collected includes referral sources, top pages, visit duration,
                information from the devices (device type, operating system, country and browser) used during the visit
                and more.
            </Typography.Paragraph>

            <Typography.Title>
                Changes and questions
            </Typography.Title>
            <Typography.Paragraph>
                We may update this policy as needed to comply with relevant regulations and reflect any new practices.
                Whenever we make a significant change to our policies, we will also announce them on our company blog or
                social media profiles.
            </Typography.Paragraph>

            <Typography.Paragraph>
                <Typography.Link to={"mailto:hey@a-b.tools"}>Contact us</Typography.Link> if you have any questions,
                comments, or concerns about this privacy policy, your data, or your rights with respect to your
                information.
                <br/>
                <br/>
                Last updated: November 15, 2021
            </Typography.Paragraph>

        </div>
    )
}
