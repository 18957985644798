import {red, green, yellow} from "@ant-design/colors";
import {useParams} from "react-router-dom";
import {useFetch} from "use-http";
import {Progress, Statistic, Table} from "antd";
import {PageHeader} from "./workspaceListView";
import {useEffect, useState} from "react";


export const SubscriptionSummary = () => {
    let [data, setData] = useState([])
    let {wsId} = useParams()
    const {response, loading, get, post} = useFetch()

    useEffect(() => {
        loadQuotaData()
    }, []) // componentDidMount

    async function loadQuotaData() {
        const res = await get('quotas?subscription__workspace__slug=' + wsId)
        if (response.ok) setData(res)
    }

    let renderPeriod = (record) =>{
        let period = JSON.parse(record.period)
        return period.lower + " - " + period.upper
    }

    let renderUsage= (record) => {
        return(
            <Statistic
                valueStyle={{fontSize:"14px"}}
                value={record.usage}/>
        )
    }
    let renderAllowance= (record) => {
        return(
            <Statistic
                valueStyle={{fontSize:"14px"}}
                value={record.allowance}/>
        )
    }

    let renderRelativeUsage = (record) => {
        let usage = record.usage
        let allowance = record.allowance

        let relUsage = 100 - usage/allowance*100

        let setStrokeColor = () => {
            if(relUsage < 1) {
                return red[6]
            }
            if(relUsage < 15){
                return yellow[6]
            }
            else{
                return green[6]
            }
        }

        return(
            <Statistic
                valueStyle={{color: setStrokeColor(),
                fontSize:"14px"
                }}
                status={"normal"}
                value={relUsage}
                suffix="%"
            />
        )

    }

    let columns = [
        {
            title: "Period",
            dataIndex: "period",
            key: "period",
            render: (text, record) => (renderPeriod(record))
        },
        {
            title: "Allowance",
            dataIndex: "allowance",
            key: "allowance",
            render: (text, record) => (renderAllowance(record))
        },
        {
            title: "Usage",
            dataIndex: "usage",
            key: "usage",
            render: (text, record) => (renderUsage(record))
        },
        {
            title: "Remaining Quota",
            dataIndex: "usage2",
            key: "usage2",
            render: (text, record) => (renderRelativeUsage(record))
        },
    ]


    return (
        <div>
            <PageHeader title={"Quota Usage"}/>
            <Table dataSource={data} loading={loading} columns={columns} rowKey={record => record.id}/>
        </div>
    )
}
