import React from 'react';
import {Button, Col, Collapse, Form, InputNumber, Row} from 'antd';

let NumericInput = (props) => {
    return (
        <Form.Item
            name={props.name}
            label={props.label}
            rules={[{required: true, message: 'This field is required'}]}
            tooltip={props.tooltip}
            style={{marginBottom: "12px"}}
            stringMode={props.stringMode === true}
        >
            <InputNumber min={props.min === undefined? 1 :props.min} placeholder={props.label} style={{width: "100%"}}/>
        </Form.Item>
    )
}

export const CalculatorFormExpertSettings = props => {
    const {Panel} = Collapse;
    const tooltipRope = "Region of Practical Equivalence"
    const tooltipPrior = "Prior hiews (alpha), hits (beta)"
    return (

        <Collapse defaultActiveKey={[]} bordered={false}
        >
            <Panel header="Expert Settings" key="1"
                   forceRender={true}
                   style={{
                       marginBottom: "24px",
                       overflow: "hidden",
                       background: "#f7f7f7",
                       border: "0px",
                   }}
            >

                <Form
                    form={props.form}
                    layout="vertical"
                    name="expert"
                    initialValues={{
                        prior_alpha: 1,
                        prior_beta: 1,
                        rope_pct: 1,
                        }}
                >

                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <NumericInput
                                name={"prior_alpha"}
                                label="Prior Alpha"
                                tooltip={tooltipPrior}
                            />
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <NumericInput
                                name={"prior_beta"}
                                label="Prior Beta"
                                tooltip={tooltipPrior}
                            />
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <NumericInput
                                name={"rope_pct"}
                                label="ROPE (%)"
                                tooltip={tooltipRope}
                                min={0}
                                stringMode={true}
                            />
                        </Col>
                        <Col className="gutter-row" span={24}>
                        <br/>
                            {props.children}
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Collapse>
    )
}


const CalculatorFormBasicSettings = props => {
    const tooltipTrials = "All users, including successes"
    const tooltipSuccesses = "Successes (Hits, Conversions)"

    return (

        <Form
            form={props.form}
            layout="vertical"
            name="basic"
            initialValues={
                {
                    trials_control: 150,
                    trials_test: 150,
                    hits_control: 36,
                    hits_test: 50,
                }
            }
        >
            <Row gutter={[16, 8]}>


                <Col xs={12} className="gutter-row">
                    <NumericInput
                        name={"trials_control"}
                        label="Trials Control"
                        tooltip={tooltipTrials}
                    />
                </Col>
                <Col xs={12} className="gutter-row">
                    <NumericInput
                        name={"trials_test"}
                        label="Trials Test"
                        tooltip={tooltipTrials}
                    />
                </Col>
                <Col xs={12} className="gutter-row">
                    <NumericInput
                        name={"hits_control"}
                        label="Hits Control"
                        tooltip={tooltipSuccesses}
                    />
                </Col>
                <Col xs={12} className="gutter-row">
                    <NumericInput
                        name={"hits_test"}
                        label="Hits Test"
                        tooltip={tooltipSuccesses}
                    />
                </Col>
            </Row>
        </Form>
    )
}
export const CalculatorForm = (props) => {
    const [form] = Form.useForm();
    const onFinish = () => {
        let validate = form.validateFields()
        validate.then((values => {
                props.postCalc(values)
            })
        )
    }


    return (
        <Row gutter={[16, 8]}>
            <Col className="gutter-row" span={24}>
                <CalculatorFormBasicSettings form={form}/>
            </Col>
            <Col className="gutter-row" span={24}>
                <CalculatorFormExpertSettings form={form}/>
            </Col>
            <Col className="gutter-row" span={24}>
                <Button type="primary" onClick={onFinish} style={{width: "100%"}} size={"large"}
                        loading={props.loading}>Run Calculation</Button>
            </Col>
        </Row>
    );
};
