import {Redirect} from "react-router-dom";
import React from 'react'
import jwt_decode from "jwt-decode";
import {notify} from "./notify";

const tokenIsExpired = (token) => {
    let decoded = jwt_decode(token);
    return decoded.exp < Date.now() / 1000
}

async function getFreshAccessToken(refreshToken) {
    let response = await fetch(process.env.REACT_APP_API_URL+'/auth/jwt/refresh/', {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({refresh: refreshToken}),
    })
    if (response.ok) {
        let data = await response.json();
        return data.access
    }
    return false
}

async function refreshAccessToken() {
    let accessToken = localStorage.getItem("access")
    let refreshToken = localStorage.getItem("refresh")
    if (!tokenIsExpired(accessToken)) {
        return accessToken
    }
    if (tokenIsExpired(refreshToken)) {
        return false
    } else {
        let newAccessToken = await getFreshAccessToken(refreshToken)
        if (newAccessToken) {
            localStorage.setItem("access", newAccessToken)
            return newAccessToken
        } else {
            notify("error", "Error", "Something went wrong - please try again")
            return false
        }
    }
}


function storeToken(token) {
    for (const [key, value] of Object.entries(token)) {
        localStorage.setItem(key, value)
    }
}

function destroyToken() {
    ["access", "refresh"].forEach(value => localStorage.removeItem(value))
}

export const isLoggedIn = () => {
    let refresehToken = localStorage.getItem("refresh")
    return !(refresehToken === null || tokenIsExpired(refresehToken));
}

class ProtectedRoute extends React.Component {
    render() {
        const Component = this.props.component;
        if(isLoggedIn()){
            return <Component/>
        }
        else{
            notify("warning", "Unauthorized", "Please log-in to continue")
            return <Redirect to={{pathname: '/login'}}/>
        }
    }
}

const authHelper = {
    storeToken: storeToken,
    refreshAccessToken: refreshAccessToken,
    destroyToken: destroyToken,
}

export {
    ProtectedRoute,
    authHelper
}
