import {useHistory, useParams} from "react-router-dom";
import {useFetch} from "use-http";
import {Table} from "antd";
import {PageHeader} from "./workspaceListView";
import {useEffect, useState} from "react";
import {LinkButton} from "../common/input";
import {formatDate} from "../common/formatters";
import {tableLoading} from "../common/misc";


export const ExperimentListView = () => {
    let [data, setData] = useState([])
    let {wsId} = useParams()
    let history = useHistory()
    const {response, loading, get, post} = useFetch()

    useEffect(() => { loadExperiments() }, []) // componentDidMount

    async function loadExperiments(){
        const res = await get('experiments?workspace=' + wsId)
        if (response.ok) setData(res)
    }

    async function handleClick () {
        const res = await post('/experiments/', {workspace:wsId})
        if(response.ok){
            history.push("/app/workspace/"+wsId+"/"+res.id+"/edit")
        }
    }
    let columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Description",
            dataIndex: "description",
            responsive: ['md'],
            key: "description"
        },
        {
            title: "Created",
            dataIndex: "created",
            key: "created",
            responsive: ['md'],
            render: (text, record) => formatDate(record.created)
        },
        {
            title: "",
            key: "edit",
            dataIndex: "id",
            render: (text, record) => <LinkButton to={`/app/workspace/${wsId}/${record.id}/edit`} text={"Edit"}/>
        },
        {
            title: "",
            key: "view",
            dataIndex: "id",
            render: (text, record) => <LinkButton to={`/app/workspace/${wsId}/${record.id}/results`} text={"View Results"}/>
        },
    ]


    return (
        <div>
            <PageHeader title={"Experiments"} buttonText={"New Experiment"} action={handleClick} />
            <Table dataSource={data} loading={tableLoading(loading)} columns={columns} rowKey={record => record.id}/>
        </div>
    )
}
