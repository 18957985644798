import {Form} from "antd";
import {useFetch} from "use-http";
import {useHistory, useParams} from "react-router-dom";
import {PageHeader} from "./workspaceListView";
import {notify} from "../common/notify";
import {BaseForm, FormFieldDescription, FormFieldName, FormFieldSlug,} from "../common/forms";
import {useEffect, useState} from "react";
import {DangerZone} from "../common/layout";
import {DangerButton} from "../common/input";


export const WorkspaceEditView = () => {
    const [form] = Form.useForm();
    const history = useHistory()
    let {wsId} = useParams()
    const [initialValues, setInitialValues] = useState({})
    const {loading, response, get, patch, del} = useFetch()

    useEffect(() => {
        loadInitialValues()
    }, []) // componentDidMount

    async function loadInitialValues() {
        const res = await get(`/workspaces?slug=${wsId}`)
        if (response.ok) setInitialValues(res[0])
    }

    async function onFinish(values) {
        let touchedFields = form.getFieldsValue(null, meta => meta.touched)
        const res = await patch(`workspaces/${initialValues.id}/`, {...touchedFields})
        if (response.ok) {
            let prevSlug = initialValues.slug
            setInitialValues(res)
            if(prevSlug !== res.slug){
                history.push(`/app/workspace/${res.slug}/edit`)
            }

        } else {
            // !== 200
            notify("error", "Error", JSON.stringify(res))
        }
    }

    const onCancel = (errorInfo) => {
        history.push("/app/workspaces")
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    async function onDelete() {
        await del(`/workspaces/${initialValues.id}/`)
        if (response.ok) onCancel()
    }

    let handleClick = () =>{
        history.push(`/app/workspace/${wsId}`)
    }

    return (
        <div>
            <PageHeader title={"Edit Workspace"} buttonText={"Open Workspace"} action={handleClick} />
            <BaseForm
                title={"General"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onCancel={onCancel}
                loading={loading}
                form={form}
                initialValues={initialValues}
            >
                <FormFieldName/>
                <FormFieldSlug/>
                <FormFieldDescription/>

            </BaseForm>

            <DangerZone>
                <DangerButton
                    confirmLabel={
                        <div>Are you sure to delete this Workspace? <br/>
                            All experiments and their collected results will be lost.
                            <br/> This action can not be undone!
                        </div>
                    }
                    onDelete={onDelete}
                    buttonText={"Delete Workspace"}
                />
            </DangerZone>
        </div>
    )
}
