import React from "react";
import {Button, Card, Col, Collapse, Divider, Layout, Menu, Row, Space, Typography} from 'antd';
import Icon from '@ant-design/icons';
import {ReactComponent as LogoSvg} from './icon.svg';
import {Link, useHistory, useLocation} from "react-router-dom";
import {LogoButton, LogOutButton} from "./input";

const {Header: AntHeader, Content, Footer, Sider} = Layout;

const AppFooter = () => {
    return (
        <Footer style={{
            textAlign: 'center',
        }}>

            <Row justify="start">
                <Col span={1}/>
                <Col>
                    <Card bordered={false}
                          title={
                              <span>
                                  <Icon component={LogoSvg}/> A-B.Tools
                              </span>
                          }
                          size="small"
                          style={{width: 300, background: "none", textAlign: "left"}}>
                        <Typography.Paragraph>
                            Made and hosted in the EU 🇪🇺
                        </Typography.Paragraph>
                    </Card>
                </Col>
                <Col>
                    <Card bordered={false}
                          title={"About"}
                          size="small"
                          style={{width: 300, background: "none", textAlign: "left"}}>
                        <Space direction={"vertical"}>

                        <Typography.Link href={"https://docs.a-b.tools"}
                                         target={"_blank"}>Documentation</Typography.Link>
                        <Link to={"/privacy"}>Privacy</Link>
                        <Typography.Link href={"https://gitlab.com/ab-tools"}
                                         target={"_blank"}>GitLab</Typography.Link>
                        </Space>
                    </Card>
                </Col>
                <Col>
                    <Card bordered={false}
                          title={"Links"}
                          size="small"
                          style={{width: 300, background: "none", textAlign: "left"}}>
                        <Link to={"/calculator"} target={"_blank"}>Calculator</Link>
                        <br/>
                    </Card>
                </Col>
            </Row>

        </Footer>
    )
}


const AppSider = () => {
    let location = useLocation()
    let history = useHistory();
    const {SubMenu} = Menu;
    let handleClick1 = () => {
        let wsId = location.pathname.split("/app/workspace/")[1].split("/")[0]
        history.push("/app/workspace/" + wsId)
    }
    let handleClick2 = () => {
        let wsId = location.pathname.split("/app/workspace/")[1].split("/")[0]
        history.push("/app/workspace/" + wsId + "/edit")
    }
    let handleClick3 = () => {
        let wsId = location.pathname.split("/app/workspace/")[1].split("/")[0]
        history.push("/app/workspace/" + wsId + "/subscription")
    }
    return (
        <Sider width={200}
               breakpoint="lg"
               collapsedWidth="0"
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['2']}
                style={{height: '100%', borderRight: 0}}
            >
                <Menu.Item key="1" onClick={handleClick1}>Experiments</Menu.Item>
                <SubMenu key="2" title="Workspace">
                    <Menu.Item key="21" onClick={handleClick2}>Settings</Menu.Item>
                    <Menu.Item key="22" onClick={handleClick3}>Subscription</Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    )
}


export let DangerZone = (props) => {
    const {Panel} = Collapse;
    return (
        <Collapse defaultActiveKey={[]} ghost
                  className={"ant-collapse-header-custom"}>
            <Panel header={
                <span style={{fontSize: "24px"}}>
                    Danger Zone
                    <Divider style={{marginBottom: 0, marginTop: 24}}/>
                </span>
            } key={4}>
                <Card ghost bordered={false}>
                    <Space size={"large"}>
                        {props.children}
                    </Space>
                </Card>
            </Panel>
        </Collapse>
    )
}


export const ContentCard = (props) => {
    return (
        <div style={{paddingTop: "20px"}}>
            <div style={{textAlign: "left"}}>
                <Typography.Title level={3}>
                    {props.title}
                </Typography.Title>
            </div>
            <Divider/>
            <Card bordered={false} style={{textAlign: "left"}}>
                {props.children}
            </Card>

        </div>
    )
}

export const PublicView = (props) => {
    return (
        <div>
            <Row>
                <Col
                    xs={{span: 24, offset: 0}}
                    lg={{span: 18, offset: 3}}
                >
                    {props.children}
                </Col>
            </Row>
        </div>
    )
}
export const AppView = (props) => {
    if (props.showSider === false) {
        return (
            <Row>
                <Col
                    xs={{span: 24, offset: 0}}
                    sm={{span: 20, offset: 2}}
                    md={{span: 18, offset: 4}}
                    xl={{span: 14, offset: 5}}
                >
                    {props.children}
                </Col>
            </Row>
        )
    }
    return (
        <Row>
            <Col
                xs={{span: 24, offset: 0}}
                md={{span: 18, offset: 2}}
                lg={{span: 14, offset: 4}}
            >
                {props.children}
            </Col>
        </Row>
    )
}

const Header = props => {
    if (props.private) {
        return (
            <AntHeader className="header">
                <Row wrap={false}>
                    <Col flex="none">
                        <LogoButton private/>
                    </Col>
                    <Col flex="auto"/>
                    <Col flex="none">
                        <LogOutButton/>
                    </Col>
                </Row>
            </AntHeader>
        )
    }
    return (
        <AntHeader className={"header"}>
            <Row wrap={false}>
                <Col flex="none">
                    <LogoButton/>
                </Col>
                <Col flex="auto"/>
                <Col flex="none">
                    <Space direction={"horizontal"} style={{width:"100%"}} size={"large"}>
                        <Link to={"/login"}>
                            <Button type={"secondary"} shape={"round"}>Log-In</Button>
                        </Link>
                        <Link to={"/register"}>
                            <Button type={"primary"} shape={"round"}>Register</Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
        </AntHeader>
    )
}


export const PrivateLayout = (props) => {
    const showSider = props.showSider
    return (
        <Layout>
            <Header private/>
            <Layout>
                {!showSider ? <div/> : <AppSider/>}
                <Layout>
                    <Content>
                        <div className="site-layout-content app-content">
                            <AppView showSider={!showSider}>
                                {props.children}
                            </AppView>
                        </div>
                    </Content>
                </Layout>
            </Layout>
            <AppFooter/>
        </Layout>
    )
}

export const PublicLayout = (props) => {
    return (
        <Layout>
            <Header/>
            <Content>
                <div className="site-layout-content app-content">
                    <Row>
                        <Col
                            xs={{span: 22, offset: 1}}
                            md={{span: 18, offset: 3}}
                            lg={{span: 16, offset: 4}}
                            xl={{span: 12, offset: 6}}
                        >
                            {props.children}
                        </Col>
                    </Row>
                </div>
            </Content>
            <AppFooter/>
        </Layout>
    )
}


