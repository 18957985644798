import {notification} from 'antd';

export const notify = (type, message, description) => {
    const defaultMessage = {
        success:"Success",
        info:"Info",
        warning:"Warning",
        error:"Error",
    }
    notification[type]({
        message: message !== undefined ? message:defaultMessage[type],
        key:message,
        description: description ? description :""
    });
}
